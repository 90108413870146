
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class ApplicantsList extends Vue {

    @Prop({
        default: () => []
    }) applicants: OM.ApplicantVM[];

    created() {}

    get otherPeopleCount() {
        return this.applicants.length - 4;
    }

}
