
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CardShadowInfo from '../cardShadowInfo.vue';
import ApplicantsList from '../applicantsList.vue';
import * as OM from '@/Model';

@Options({
    components: {
        CardShadowInfo,
        ApplicantsList
    }
})
export default class active extends Vue {

    @Prop({
        default: () => new OM.MyExperienceDetailVM()
    }) experience: OM.MyExperienceDetailVM;

    created() {
    }

}
