import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "candidatiContainer" }
const _hoisted_2 = {
  key: 1,
  class: "fev_text4 fw_600 ms-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.applicants.length == 0)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 0,
          localizedKey: "app_AncoraNessunCandidato",
          text: "-- Ancora nessun candidato",
          class: "fev_text7 fc_lightGrey mt-3"
        }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.applicants, (applicant, index) => {
      return (_openBlock(), _createBlock("div", {
        key: index,
        class: "negativeLeftMargin"
      }, [
        (index < 4)
          ? (_openBlock(), _createBlock("div", {
              key: 0,
              class: "imageContainer",
              style: 'background-image: url(\'' + applicant.imageUrl + '\');'
            }, null, 4))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    (_ctx.otherPeopleCount > 0)
      ? (_openBlock(), _createBlock("span", _hoisted_2, "+" + _toDisplayString(_ctx.otherPeopleCount), 1))
      : _createCommentVNode("", true)
  ]))
}