import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card_shadow_info = _resolveComponent("card-shadow-info")
  const _component_applicants_list = _resolveComponent("applicants-list")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_card_shadow_info, { experience: _ctx.experience }, null, 8, ["experience"]),
    _createVNode(_component_applicants_list, {
      applicants: _ctx.experience.applicants
    }, null, 8, ["applicants"])
  ], 64))
}