
import { Options, Vue } from 'vue-class-component';
import { ExperienceClient } from '@/services/Services';
import CardShadowInfo from '../components/experience/cardShadowInfo.vue';
import bodymovin from 'lottie-web';
import pending from '../components/experience/experienceListComponents/pending.vue';
import draft from '../components/experience/experienceListComponents/draft.vue';
import active from '../components/experience/experienceListComponents/active.vue';
import past from '../components/experience/experienceListComponents/past.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: {
        CardShadowInfo,
        pending,
        draft,
        active,
        past
    }
})
export default class MyExperience extends Vue {

    experienceList: { [key: string]: OM.MyExperienceDetailVM[]} = {};
    selectedTab: string = "active";
    keys: string[] = [];

    created() {
        this.keys = Object.keys(VM.ExperienceTypeVM);

        this.keys.forEach(key => {
            this.experienceList[key] = [];
        });

        this.init();
    }

    init() {
        Promise.all([
            ExperienceClient.myActiveList(),
            ExperienceClient.myPendingList(),
            ExperienceClient.myDraftList(),
            ExperienceClient.myActivePastList(),
        ])
        .then(xs => {
            this.experienceList[VM.ExperienceTypeVM.active] = xs[0];
            this.experienceList[VM.ExperienceTypeVM.pending] = xs[1];
            this.experienceList[VM.ExperienceTypeVM.draft] = xs[2];
            this.experienceList[VM.ExperienceTypeVM.past] = xs[3];
            
            this.selectedTab = VM.ExperienceTypeVM.active;

            var tempKey = this.keys.map( x => {
                return { key: x, list: this.experienceList[x] }
            }).filter( x => x.list.length > 0)
            .map( x => x.key)

            if(tempKey.length > 0)
                this.selectedTab = tempKey[0];

            if(this.selectedTab == VM.ExperienceTypeVM.draft)
                this.scrollToTab();
        })
    }

    mounted(){
        setTimeout(() => {
            var experienceLoop = bodymovin.loadAnimation({
                container: <any>this.$refs.experienceLoop,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './json/experience_loop.json',
            })

            experienceLoop.play();
        }, 300);
    }

    scrollToTab() {
        var el = this.$refs.filters_scrollable as HTMLElement;
        el.scrollLeft = el.scrollWidth;
    }

    goTo(tab: string, experienceId: string){
        if(tab == VM.ExperienceTypeVM.draft)
            this.goToEdit(experienceId);
        else
            this.goToDetail(experienceId);
    }

    goToDetail(experienceId: string) {
        this.$router.push('/experience/detail/' + experienceId);
    }
    goToEdit(experienceId: string) {
        this.$router.push('/experience/edit/' + experienceId);
    }

    selectTab(tab: string){
        if(this.experienceList[tab].length > 0)
            this.selectedTab = tab;
    }
}
