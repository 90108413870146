
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CardShadowInfo from '../cardShadowInfo.vue';
import * as OM from '@/Model';

@Options({
    components: {
        CardShadowInfo
    }
})
export default class pending extends Vue {

    @Prop() experience: OM.MyExperienceDetailVM;

    created() {
    }

}
